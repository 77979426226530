import React from 'react';
const Terms = () => (
    <section className="terms container">
        <h1 className="mb-8">Spread Wonder Sweepstakes - Official Rules</h1>
        <p>NO PURCHASE NECESSARY TO ENTER OR WIN. VOID WHERE PROHIBITED OR RESTRICTED BY LAW</p>
        <p>
            1. PROMOTION DESCRIPTION: The Spread Wonder Sweepstakes ("Sweepstakes") begins on 10/01/2020 at 9am ET and
            ends on 11/30/2020 at 5pm ET ("Promotion Period"). The sponsor of this Sweepstakes is Findaway ("Sponsor").
            By participating in the Sweepstakes, each Entrant unconditionally accepts and agrees to comply with and
            abide by these Official Rules and the decisions of Sponsor, which shall be final and binding in all
            respects. Sponsor is responsible for the collection, submission or processing of Entries and the overall
            administration of the Sweepstakes. Entrants should look solely to Sponsor with any questions, comments or
            problems related to the Sweepstakes. Sponsor may be reached by email at marketing@findaway.com during the
            Promotion Period.
        </p>
        <p>
            2. ELIGIBILITY: Open to legal residents of the 50 United States, the District of Columbia, and Canada who
            are 18 years old and over and are employed by a school, public or private library. ("Entrant"). Sponsor, and
            their respective parents, subsidiaries, affiliates, distributors, retailers, sales representatives,
            advertising, and promotion agencies and each of their respective officers, directors and employees
            ("Promotion Entities"), are ineligible to enter the Sweepstakes or win. Household Members and Immediate
            Family Members of such individuals are also not eligible to enter or win. This Sweepstakes is subject to all
            applicable federal, state, and local laws and regulations and is void where prohibited or restricted by law.
        </p>
        <p>3. PRIZES: </p>
        <p>Grand Prize: One (1) library will receive a 5000-dollar ($5,000) Wonderbook collection.</p>
        <p>
            Only one prize will be awarded. Prizes cannot be transferred, redeemed for cash, or substituted by winner.
            Sponsor reserves the right to award a substitute prize of equal or greater value if a prize described in
            these Official Rules is unavailable or cannot be awarded. Sponsor makes no representation or warranty
            concerning the appearance, safety or performance of any prize awarded. Restrictions, conditions, and
            limitations may apply. Sponsor will not replace any lost or stolen prize items.
        </p>
        <p>
            4. HOW TO ENTER: Enter the Sweepstakes during the Promotion Period online by visiting the entry form, which
            can be found on the following website at http://spread-wonder.com/. One Entry per Entrant.
        </p>
        <p>
            Automated or robotic Entries submitted by individuals or organizations will be disqualified. Internet entry
            must be made by the Entrant. Any attempt by Entrant to obtain more than the stated number of Entries by
            using multiple/different email addresses, identities, registrations, logins, or any other methods will void
            Entrant's Entries and that Entrant may be disqualified. Final eligibility for the award of any prize is
            subject to eligibility verification as set forth below. All Entries must be posted by the end of the
            Promotion Period in order to participate. Sponsor's database clock will be the official timekeeper for this
            Sweepstakes.
        </p>
        <p>
            5. WINNER SELECTION: The Winner of the Sweepstakes will be selected in a random drawing from among all
            eligible Entries received throughout the Promotion Period. The random drawing will be conducted about two
            (2) weeks after the Promotion Period by Sponsor or its designated representatives, whose decisions are
            final.
        </p>
        <p>
            6. WINNER NOTIFICATION: Winner will be notified by email at the email address provided in the Entry
            Information approximately two (2) weeks after the random drawing. Potential Winner must accept a prize by
            email as directed by Sponsor within one (1) week of notification. Sponsor is not responsible for any delay
            or failure to receive notification for any reason, including inactive email account(s), technical
            difficulties associated therewith, or Winner’s failure to adequately monitor any email account.
        </p>
        <p>
            Any winner notification not responded to or returned as undeliverable may result in prize forfeiture. The
            potential prize winner may be required to sign and return an affidavit of eligibility and release of
            liability, and a Publicity Release (collectively \"the Prize Claim Documents\"). No substitution or transfer
            of a prize is permitted except by Sponsor.
        </p>
        <p>
            7. PRIVACY: Any personal information supplied by you will be subject to the privacy policy of the Sponsor
            posted at https://www.iubenda.com/privacy-policy/7972834. By entering the Sweepstakes, you grant Sponsor
            permission to share your email address and any other personally identifiable information with the other
            Sweepstakes Entities for the purpose of administration and prize fulfillment.
        </p>
        <p>
            8. LIMITATION OF LIABILITY: Sponsor assumes no responsibility or liability for (a) any incorrect or
            inaccurate entry information, or for any faulty or failed electronic data transmissions; (b) any
            unauthorized access to, or theft, destruction or alteration of entries at any point in the operation of this
            Sweepstakes; (c) any technical malfunction, failure, error, omission, interruption, deletion, defect, delay
            in operation or communications line failure, regardless of cause, with regard to any equipment, systems,
            networks, lines, satellites, servers, camera, computers or providers utilized in any aspect of the operation
            of the Sweepstakes; (d) inaccessibility or unavailability of any network or wireless service, the Internet
            or website or any combination thereof; (e) suspended or discontinued Internet, wireless or landline phone
            service; or (f) any injury or damage to participant's or to any other person’s computer or mobile device
            which may be related to or resulting from any attempt to participate in the Sweepstakes or download of any
            materials in the Sweepstakes.
        </p>
        <p>
            If, for any reason, the Sweepstakes is not capable of running as planned for reasons which may include
            without limitation, infection by computer virus, tampering, unauthorized intervention, fraud, technical
            failures, or any other causes which may corrupt or affect the administration, security, fairness, integrity
            or proper conduct of this Sweepstakes, the Sponsor reserves the right at its sole discretion to cancel,
            terminate, modify or suspend the Sweepstakes in whole or in part. In such event, Sponsor shall immediately
            suspend all drawings and prize awards, and Sponsor reserves the right to award any remaining prizes (up to
            the total ARV as set forth in these Official Rules) in a manner deemed fair and equitable by Sponsor.
            Sponsor and Released Parties shall not have any further liability to any participant in connection with the
            Sweepstakes.
        </p>
    </section>
);

export default Terms;
